var render = function render(){var _vm=this,_c=_vm._self._c;return _c('MultiFilesTable',{staticClass:"files",attrs:{"listType":"multi","s3Folder":"sales","moduleName":`sale`,"customerId":_vm.sale.details.raw.customer_id,"moduleId":_vm.sale.id,"multiIds":[
    {
      module: 'opportunity',
      id: _vm.sale.details.raw.opportunity_id,
    },
    {
      module: 'contract',
      id: _vm.sale.details.raw.contract_id,
    },
    {
      module: 'sale',
      id: _vm.sale.details.id,
    },
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }