<template>
  <MultiFilesTable
    class="files"
    listType="multi"
    s3Folder="sales"
    :moduleName="`sale`"
    :customerId="sale.details.raw.customer_id"
    :moduleId="sale.id"
    :multiIds="[
      {
        module: 'opportunity',
        id: sale.details.raw.opportunity_id,
      },
      {
        module: 'contract',
        id: sale.details.raw.contract_id,
      },
      {
        module: 'sale',
        id: sale.details.id,
      },
    ]"
  />
</template>

<script>
import MultiFilesTable from "@/components/general/MultiFilesTable.vue";
export default {
  props: {
    tempContract: Object,
    sale: Object,
  },
  components: { MultiFilesTable },
};
</script>

<style lang="sass" scoped>
.files
  padding: 20px 10px 0 0
</style>
